import { Link } from "gatsby";
import React from "react";
import ArrowRightSVG from "../../global/assets/arrow-right.svg";
import { slugify } from "../../includes/utils";
import "./IndexSpaceLink.scss";

export interface IIndexSpaceLinkProps {
  slug?: string;
  title: string;
  description: string;
  icon: JSX.Element;
}

const IndexSpaceLink = ({ slug, title, description, icon }: IIndexSpaceLinkProps): JSX.Element => {
  if (!slug) {
    slug = slugify(title);
  }

  return (
    <Link to={`/${slug}`} className={`IndexSpaceLink IndexSpaceLink--${slug}`}>
      <div className="IndexSpaceLink__content">
        <h2>{title}</h2>
        <p>{description}</p>
        <p>
          <span>Explore {title.toLowerCase()} topics</span>
          <ArrowRightSVG/>
        </p>
      </div>
      {icon}
    </Link>
  );
};
export default IndexSpaceLink;