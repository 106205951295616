import React from "react";
import { graphql } from "gatsby";
import GlobalLayout from "../components/global/GlobalLayout";
import IndexBody from "../components/index/IndexBody";

interface IIndexProps {
  data: IIndexQueryData;
}

const Index = ({ data }: IIndexProps): JSX.Element => {
  return (
    <GlobalLayout siteMetadata={data.site.siteMetadata} navShadowHidden={true}>
      <IndexBody/>
    </GlobalLayout>
  );
};
export default Index;

interface IIndexQueryData {
  site: {
    siteMetadata: {
      name: string;
      description: string;
    }
  }
}

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;