import { Link } from "gatsby";
import React from "react";
import HeaderBackground from "../../global/assets/homepage-bg.svg";
import ArrowRightSVG from "../../global/assets/arrow-right.svg";
import "./IndexHeader.scss";

const IndexHeader = (): JSX.Element => {
  return (
    <div className="IndexHeader">
      <HeaderBackground className="IndexHeader__background"/>
      <div className="IndexHeader__content">
        <h1>Our Knowledge at Your Fingertips</h1>
        <p>Our support documents are tailor-made to suit your needs, giving you insights from build approval to launch</p>
        <Link to="/search">
          <span>Start exploring now</span>
          <ArrowRightSVG/>
        </Link>
      </div>
    </div>
  );
};
export default IndexHeader;