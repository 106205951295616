import React from "react";
import IndexHeader from "./IndexHeader";
import IndexSpaceLink, { IIndexSpaceLinkProps } from "./IndexSpaceLinks";
//import ArticleResultCard from "../search/ArticleResultCard";
import BulbSVG from "../../global/assets/bulb.svg";
import ClipboardSVG from "../../global/assets/clipboard.svg";
import GearSVG from "../../global/assets/gear.svg";
import BugSVG from "../../global/assets/bug.svg";
import "./IndexBody.scss";

const IndexBody = (): JSX.Element => {
  const spaceLinks: IIndexSpaceLinkProps[] = [
    {
      slug: "general",
      title: "General Topics",
      description: "For the times when you aren't quite sure what you're looking for, here's your starting point",
      icon: <BulbSVG className="IndexSpaceLink__icon"/>
    },
    {
      title: "Growth Strategy",
      description: "Expansion through statistical significance",
      icon: (
        <div className="IndexSpaceLink__icon">
          <div className="IndexSpaceLink__iconHolder"/>
        </div>
      )
    },
    {
      title: "Program Management",
      description: "Creating satisfaction from efficiency",
      icon: <ClipboardSVG className="IndexSpaceLink__icon"/>
    },
    {
      title: "Engineering",
      description: "Tailor made solutions for any product",
      icon: <GearSVG className="IndexSpaceLink__icon"/>
    },
    {
      title: "Quality Assurance",
      description: "Squashing bugs, one review at a time",
      icon: <BugSVG className="IndexSpaceLink__icon"/>
    }
  ];

  const getSpaceLinks = (): JSX.Element[] => {
    return spaceLinks.map(data => <IndexSpaceLink {...data} key={data.title}/>);
  };

  return <>
    <IndexHeader/>
    <div className="IndexBody">
      <div className="IndexBody__spaceCardsWrapper">
        <section className="IndexBody__spaceCards">{getSpaceLinks()}</section>
      </div>
      <div className="IndexBody__contactWrapper">
        <section className="IndexBody__contact">
          <h2>Not finding what you're looking for? Feeling overwhelmed?</h2>
          <p>We're here to help, whatever your needs may be. <a href="https://www.crometrics.com/contact" target="_blank" rel="noreferrer">Get in touch today</a>.</p>
        </section>
      </div>
      {/* <div className="IndexBody__popularArticlesWrapper">
        <section className="IndexBody__popularArticles">
          <h3>Popular Articles</h3>
          <ArticleResultCard title={`Dynamic CSS Classes & CSS-in-JS`} createdDate={new Date(Date.now())} readTime={20} slug="dynamic-css-classes-and-css-in-js" labels={["engineering", "css"]}/>
          <ArticleResultCard title={`Dynamic CSS Classes & CSS-in-JS`} createdDate={new Date(Date.now())} readTime={20} slug="dynamic-css-classes-and-css-in-js" labels={["engineering", "css"]}/>
          <ArticleResultCard title={`Dynamic CSS Classes & CSS-in-JS`} createdDate={new Date(Date.now())} readTime={20} slug="dynamic-css-classes-and-css-in-js" labels={["engineering", "css"]}/>
          <ArticleResultCard title={`Dynamic CSS Classes & CSS-in-JS`} createdDate={new Date(Date.now())} readTime={20} slug="dynamic-css-classes-and-css-in-js" labels={["engineering", "css"]}/>
          <ArticleResultCard title={`Dynamic CSS Classes & CSS-in-JS`} createdDate={new Date(Date.now())} readTime={20} slug="dynamic-css-classes-and-css-in-js" labels={["engineering", "css"]}/>
          <ArticleResultCard title={`Dynamic CSS Classes & CSS-in-JS`} createdDate={new Date(Date.now())} readTime={20} slug="dynamic-css-classes-and-css-in-js" labels={["engineering", "css"]}/>
        </section>
      </div> */}
    </div>
  </>;
};
export default IndexBody;